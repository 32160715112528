
.container {
  padding-top: 20px;
}
.content {
  p {
    padding-bottom: 20px !important;
  }
}

.header {
  background: #1d73f4;
  padding: 50px 0;
  .logo {
    max-width: 40%;
  }
  .title,
  .subtitle {
    color: #fff;
  }
  .header-svg {
    position: absolute;
    &.clouds {
      top: 0;
      width: 100%;
      height: 100%;
      background: url("~@/assets/header-clouds.svg");
    }
  }
}

.image-200 {
  max-height: 200px;
}
